<i18n src="./translations"></i18n>

<template>
  <div>
    <h1
      class="checkout-login-title"
      :class="[
        {
          'checkout-login-title--rebranding': isRebrandingChannel,
        },
      ]"
    >
      {{ $t('checkout-login.title') }}
    </h1>
    <div class="checkout-login">
      <LoginForm
        class="login section"
        :title="$t('checkout-login.login.title')"
        :login-button-text="$t('checkout-login.login.action')"
        :forgot-password-text="$t('checkout-login.login.forgot_password')"
        :redirection-url="frontFacingPath('checkout.checkout.details')"
        :disabled="isLoading"
        :is-modal="false"
      />
      <div
        v-if="!isAlpha"
        class="new-customer section"
        :class="[
          {
            'new-customer-rebranding': isRebrandingChannel,
          },
        ]"
      >
        <h2 class="customer-title">
          {{ $t('checkout-login.new_customer.title') }}
        </h2>
        <p class="description-paragraph">
          {{ $t('checkout-login.new_customer.text.paragraph') }}
        </p>
        <ul class="description-items">
          <li class="description-item">
            <CheckBoldSvg height="12" width="12" />
            {{ $t('checkout-login.new_customer.text.items.1') }}
          </li>
          <li class="description-item">
            <CheckBoldSvg height="12" width="12" />
            {{ $t('checkout-login.new_customer.text.items.2') }}
          </li>
          <li class="description-item">
            <CheckBoldSvg height="12" width="12" />
            {{ $t('checkout-login.new_customer.text.items.3') }}
          </li>
        </ul>
        <form class="guest-form" @submit.prevent="handleGuestRegistration">
          <Email
            :form="{
              vars: {
                full_name: 'new-customer-email',
                value: newCustomerEmail,
                label: $t('checkout-login.new_customer.email'),
                required: true,
              },
            }"
            @input="newCustomerEmail = $event"
          />
          <Button
            class="register-button"
            modifier="primary"
            :modifiers="['primary', isRebrandingChannel ? 'rebranding' : '']"
            type="submit"
            :title="$t('checkout-login.new_customer.action')"
            :href="frontFacingPath('customer.shop.register')"
            :disabled="isLoading"
            :has-loading="isLoading"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import CheckBoldSvg from 'Components/00-generated/CheckBoldSvg.vue';
import Button from 'Components/01-atoms/button/Button.vue';
import Email from 'Components/01-atoms/form/email/Email.vue';
import LoginForm from 'Components/02-molecules/login-form/LoginForm.vue';
import { axios } from 'Services/AjaxService';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'CheckoutLogin',
  components: { CheckBoldSvg, LoginForm, Email, Button },
  mixins: [globalMixin],
  data() {
    return {
      newCustomerEmail: '',
      wrongCredentials: false,
      requestFailed: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('core', ['isRebrandingChannel']),
    ...mapState('core', {
      salesChannelId: ({ salesChannel }) => salesChannel.salesChannelId,
      baseUrl: ({ salesChannel }) => salesChannel.baseUrl,
    }),
    isAlpha() {
      return this.baseUrl && this.baseUrl.includes('alpha');
    },
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    async handleGuestRegistration() {
      this.isLoading = true;
      try {
        const response = await this.registerUser();

        if (response) {
          window.location.replace(
            this.frontFacingPath('checkout.checkout.details')
          );
        } else {
          this.isLoading = false;
          if (response?.messages) this.wrongCredentials = true;
          this.addMessage({
            text: this.$t('checkout-login.error'),
          });
        }
      } catch (error) {
        this.addMessage({
          text: this.$t('checkout-login.error'),
        });
        this.isLoading = false;
        this.requestFailed = true;
      }
    },
    async registerUser() {
      const requestData = {
        guest_email: this.newCustomerEmail,
        guest_or_new: true,
        submit: '',
      };

      return await axios.post('/checkout/user/login', requestData, {
        headers: {
          'Content-Type': 'application/json',
          'x-sales-channel-id': this.salesChannelId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';
@import 'mixins';
@import 'utils/fonts';

.checkout-login-title {
  margin: var(--space-1) 0;
  color: var(--color-primary);
  font-weight: 700;
  font-size: var(--font-size-L);
  line-height: var(--font-size-LLXL);
}

.checkout-login {
  display: flex;
  gap: var(--space-2);
  height: 100%;
  flex-direction: row !important; // [izerozlu] Placeholder component is applying default flex-direction: column, so I have to override here
  margin: var(--space-3) 0 var(--space-3) 0;
}

.section {
  padding: var(--space-4) max(calc(25% - 200px - 8px), var(--space-4));
  border: 1px solid var(--color-alto);
  flex-grow: 1;
  max-width: unset;
  min-width: 400px;
  box-sizing: content-box;
  max-height: 330px;
  height: 100%;
}

.new-customer {
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
}

.customer-title {
  width: 100%;
  font-weight: 700;
  color: var(--color-mine-shaft);
  font-size: var(--font-size-MML);
  line-height: var(--font-size-LLXL);
}

.description-items {
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
  line-height: var(--space-2--half);
}

.guest-form {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.register-button {
  margin-top: auto;
  text-transform: capitalize !important;
}

.login ::v-deep .buttons {
  margin-top: auto;
}

.new-customer-rebranding {
  @include rebranding-base;

  .customer-title {
    @include rebranding-para-regular;
    @include rebranding-header-base;
  }

  .description-paragraph,
  .description-items {
    @include rebranding-para-small-regular;
  }

  .guest-form .input {
    @include rebranding-x-small-regular;
  }

  .register-button {
    @include rebranding-para-regular;
    @include rebranding-header-base;

    text-transform: none !important;
  }
}

.checkout-login-title--rebranding {
  @include rebranding-h6--checkout-login;

  color: var(--color-rebranding-secondary);

  .section {
    padding: var(--space-2--half);
  }
}

@media #{$_mediaSDown} {
  .section {
    min-width: 300px;
    max-height: 500px;
    box-sizing: inherit;
  }

  .register-button {
    margin-top: var(--space-1--half);
  }
}

@media #{$_mediaMDown} {
  .checkout-login {
    flex-direction: column !important;
    margin: 0;
  }

  .login,
  .new-customer {
    width: 100%;
    max-width: 400px;
  }

  .section {
    max-height: 500px;
    padding: var(--space-2);
  }

  .register-button {
    margin-top: var(--space-1--half);
  }
}
</style>
