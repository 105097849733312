<template>
  <Input
    type="email"
    :form="form"
    :icon1="icon1"
    :modifiers="modifiers"
    :modifier="modifier"
    @input="$emit('input', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  />
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import { formMixin } from 'Libs/mixins/formMixin';
import Input from 'Components/01-atoms/form/input/Input.vue';

export default {
  name: 'Email',
  components: { Input },
  mixins: [globalMixin, formMixin],
  props: {
    icon1: {
      type: Object,
      default: null,
    },
  },
};
</script>
