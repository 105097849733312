var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"checkout-login-title",class:[
      {
        'checkout-login-title--rebranding': _vm.isRebrandingChannel,
      },
    ]},[_vm._v("\n    "+_vm._s(_vm.$t('checkout-login.title'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"checkout-login"},[_c('LoginForm',{staticClass:"login section",attrs:{"title":_vm.$t('checkout-login.login.title'),"login-button-text":_vm.$t('checkout-login.login.action'),"forgot-password-text":_vm.$t('checkout-login.login.forgot_password'),"redirection-url":_vm.frontFacingPath('checkout.checkout.details'),"disabled":_vm.isLoading,"is-modal":false}}),_vm._v(" "),(!_vm.isAlpha)?_c('div',{staticClass:"new-customer section",class:[
        {
          'new-customer-rebranding': _vm.isRebrandingChannel,
        },
      ]},[_c('h2',{staticClass:"customer-title"},[_vm._v("\n        "+_vm._s(_vm.$t('checkout-login.new_customer.title'))+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"description-paragraph"},[_vm._v("\n        "+_vm._s(_vm.$t('checkout-login.new_customer.text.paragraph'))+"\n      ")]),_vm._v(" "),_c('ul',{staticClass:"description-items"},[_c('li',{staticClass:"description-item"},[_c('CheckBoldSvg',{attrs:{"height":"12","width":"12"}}),_vm._v("\n          "+_vm._s(_vm.$t('checkout-login.new_customer.text.items.1'))+"\n        ")],1),_vm._v(" "),_c('li',{staticClass:"description-item"},[_c('CheckBoldSvg',{attrs:{"height":"12","width":"12"}}),_vm._v("\n          "+_vm._s(_vm.$t('checkout-login.new_customer.text.items.2'))+"\n        ")],1),_vm._v(" "),_c('li',{staticClass:"description-item"},[_c('CheckBoldSvg',{attrs:{"height":"12","width":"12"}}),_vm._v("\n          "+_vm._s(_vm.$t('checkout-login.new_customer.text.items.3'))+"\n        ")],1)]),_vm._v(" "),_c('form',{staticClass:"guest-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleGuestRegistration.apply(null, arguments)}}},[_c('Email',{attrs:{"form":{
            vars: {
              full_name: 'new-customer-email',
              value: _vm.newCustomerEmail,
              label: _vm.$t('checkout-login.new_customer.email'),
              required: true,
            },
          }},on:{"input":function($event){_vm.newCustomerEmail = $event}}}),_vm._v(" "),_c('Button',{staticClass:"register-button",attrs:{"modifier":"primary","modifiers":['primary', _vm.isRebrandingChannel ? 'rebranding' : ''],"type":"submit","title":_vm.$t('checkout-login.new_customer.action'),"href":_vm.frontFacingPath('customer.shop.register'),"disabled":_vm.isLoading,"has-loading":_vm.isLoading}})],1)]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }