
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="CheckBoldSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 12"><path fill-rule="evenodd" d="M15 2.3c0 .3 0 .5-.3.7l-7 7.3-1.3 1.4c-.2.2-.4.3-.7.3-.2 0-.4 0-.6-.3L4 10.3.3 6.7C0 6.5 0 6.3 0 6c0-.3 0-.5.3-.7L1.6 4l.6-.3c.3 0 .5 0 .7.2l2.7 3L12 .2c.3-.2.5-.3.8-.3.2 0 .4 0 .6.3l1.3 1.4c.2 0 .3.4.3.6z"/></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'CheckBoldSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
